<template>
  <b-overlay spinner-variant="primary" :show="isLoading">
    <div class="row">
      <div class="col-12">
        <b-card>
          <div class="d-flex">
            <b-badge variant="light-primary" class="mr-1">
              ID: {{ ticket.id }}
            </b-badge>
            <h4 class="card-title mb-0">{{ getType(ticket.type) }}</h4>
          </div>

          <h1 v-if="ticket.topic">
            {{ ticket.topic }}
          </h1>
        </b-card>
      </div>
      <div class="col-12 col-md-4">
        <b-card title="Створено">
          <span v-if="ticket.created_at && ticket.created_at.seconds">
            {{ ticket.created_at.seconds | moment("calendar") }}
          </span>
        </b-card>
      </div>
      <div class="col-12 col-md-4">
        <b-card title="Статус">
          <statusBadge :status="ticket.status" />
        </b-card>
      </div>
      <div class="col-12 col-md-4">
        <b-card title="Відправник">
          <span v-if="ticket.sender_info">
            <router-link
              v-if="sender_info"
              :to="{ name: 'user-single', params: { id: sender_info.id } }"
            >
              {{ sender_info.last_name }} {{ sender_info.first_name[0] }}.
            </router-link>
          </span>
          <span v-else>Аноінімно</span>
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Адресовано до">
          <router-link
            :to="{
              name: 'departments-single',
              params: { id: getDepartment(ticket.department).id },
            }"
          >
            {{ getDepartment(ticket.department).name }}
          </router-link>
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Текст">{{ ticket.text }}</b-card>
      </div>
      <div class="col-12">
        <b-card title="Файли">
          <div v-for="f in ticket.files" :key="f.id">
            <a :href="f.file_url" target="_blank">
              {{ f.orig_path }}
            </a>
          </div>
        </b-card>
      </div>
      <div class="col-12">
        <b-card title="Дії із запитом">
          <b-tabs content-class="mt-1">
            <b-tab title="Відповідь" active>
              <b-alert show variant="primary py-1 px-2">
                <h5>Надішліть офіційну відповідь на запит.</h5>
                За замовчуванням запит, отримає новий статус
                <strong>"Виконано"</strong>, проте, перед надсиланням відпоіді,
                його можна змінити
              </b-alert>
              <b-form-textarea
                id="response"
                v-model="ticket_event.response_text"
                placeholder="Текст офіційної відповіді"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div class="text-left">
                <textLength
                  :text="ticket_event.response_text"
                  :limit="text_limit"
                />
              </div>
              <div class="file">
                <fileUploader @update="setFiles" />
              </div>
              <div class="d-flex align-items-center mt-1 justify-content-end">
                <div class="confirm mr-auto">
                  <label
                    for="confirmation-response"
                    class="d-flex align-items-center"
                  >
                    <b-form-checkbox
                      id="confirmation-response"
                      name="confirmation-response"
                      v-model="confirmation.response"
                    ></b-form-checkbox>
                    Відповідь перевірена
                  </label>
                </div>
                <strong class="ml-auto mr-1">Наступний статус:</strong>
                <b-form-select
                  style="width: 200px"
                  v-model="ticket_event.next_status"
                  :options="statusOptions"
                  class="mr-2"
                >
                </b-form-select>
                <b-button
                  variant="primary"
                  :disabled="disableResponse == true"
                  @click="sendResponse"
                  >Надіслати відповідь</b-button
                >
              </div>
            </b-tab>
            <b-tab title="Переадресація запиту">
              <b-alert show variant="primary py-1 px-2">
                У разі невідповідності запиту до Вашого відділу, можна
                перенаправити запит на більш відповідний підрозділ.
                Перенаправивши запит на інший відділ, Ви втратите до нього
                доступ. Запит отримає новий статуc:
                <strong> "Переадресовано"</strong>
              </b-alert>

              <v-select
                id="department-parent"
                v-model="ticket_event.next_department"
                :options="departmentsOPtions"
                :reduce="(text) => text.value"
                label="text"
                placeholder="Оберіть відділ, на який потрібно переадресвати запит"
              />
              <b-form-textarea
                id="redirect"
                v-model="ticket_event.response_text"
                placeholder="Коментар до переадресації"
                class="mt-2"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div class="text-left">
                <textLength
                  :text="ticket_event.response_text"
                  :limit="text_limit"
                />
              </div>
              <div class="d-flex align-items-center mt-1 justify-content-end">
                <div class="confirm mr-auto">
                  <label
                    for="confirmation-redirect"
                    class="d-flex align-items-center"
                  >
                    <b-form-checkbox
                      id="confirmation-redirect"
                      name="confirmation-redirect"
                      v-model="confirmation.redirect"
                    ></b-form-checkbox>
                    Переадресація перевірена
                  </label>
                </div>
                <b-button
                  :disabled="disableRedirectcion"
                  variant="primary"
                  @click="makeRedirection"
                  >Переадресувати запит</b-button
                >
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import statusBadge from "@/components/ticket-status-badge";
import vSelect from "vue-select";
import textLength from "@/components/text-length.vue";
import fileUploader from "@/components/file-uploader.vue";
export default {
  components: {
    statusBadge,
    vSelect,
    textLength,
    fileUploader,
  },
  data() {
    return {
      confirmation: {
        response: false,
        redirect: false,
      },
      text_limit: 1000,
      isLoading: false,
      ticket: {},
      sender: null,
      ticket_event: {
        prev_status: 0,
        is_redirection: false,
        response_text: "",
        next_department: null,
        next_status: 1,
        user: "",
        created_at: "",
        sender: "",
        seen: false,
        id: null,
        ticket_type: "",
        files: [],
      },
    };
  },
  computed: {
    departmentsOPtions() {
      var list = this.departments.map((e) => {
        return { text: e.name, value: e.id };
      });

      //Not allow to be parent of self
      return list.filter((e) => e.value != this.ticket.department);
    },
    ...mapGetters({
      datasets: "getDatasets",
      user: "getUser",
    }),
    statusOptions() {
      var options = this.statuses.map((e) => {
        return { value: e.id, text: e.name };
      });
      return options.filter((e) => e.value !== 0 && e.value !== 3);
    },
    statuses() {
      return this.datasets.statuses;
    },
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
    disableResponse() {
      let disabled = false;
      if (
        this.confirmation.response == false ||
        this.ticket_event.response_text.length < 16 ||
        this.text_limit < this.ticket_event.response_text.length
      ) {
        disabled = true;
      }
      return disabled;
    },
    disableRedirectcion() {
      let disabled = false;
      if (
        this.confirmation.redirect == false ||
        this.ticket_event.response_text.length < 16 ||
        this.text_limit < this.ticket_event.response_text.length
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    setFiles(data) {
      this.ticket_event.files = data;
    },
    async getTicket() {
      this.isLoading = true;
      let id = Number(this.$route.params.id);
      let conditions = ["id", "==", id];
      let result = await this.$db.getCollectionWhere("tickets", conditions);
      console.log(result);
      if (result) {
        this.ticket = result[0];
      }
      if (this.ticket.sender) {
        this.getUser();
      }
      this.isLoading = false;
    },
    async getUser() {
      var user = await this.$db.getDocument("users", this.ticket.sender);
      this.sender = user;
    },
    getStatus(id) {
      let name = "";
      let status = this.statuses.find((el) => el.id == id);
      if (status) {
        name = status.name;
      }
      return name;
    },
    getType(slug) {
      var name = "";
      let type = this.ticket_types.find((el) => el.slug == slug);
      if (type) {
        name = type.nane;
      }
      return name;
    },
    getDepartment(id) {
      var department = { name: "" };
      if (this.departments) {
        department = this.departments.find((el) => el.id == id);
      }
      return department;
    },
    async addEventData() {
      //Set user
      this.ticket_event.user = this.ticket.sender;

      //Set previous status
      this.ticket_event.prev_status = this.ticket.status;

      //Set date
      var now = new Date();
      this.ticket_event.created_at = now;

      //Set ticket
      this.ticket_event.ticket = this.ticket.id;

      //Set sender
      this.ticket_event.sender = this.user.uid;

      //set type
      this.ticket_event.ticket_type = this.ticket.type;

      this.ticket_event.id = await this.$api
        .get("/get/counter/?type=notifications")
        .then((response) => {
          return response.data.count;
        })
        .catch((error) => {
          alert(error);
        });
    },
    async updateTicketStatus() {
      this.isLoading = true;
      var uid = this.ticket.uid;
      await this.$db.update("tickets", uid, {
        status: this.ticket_event.next_status,
      });
      this.isLoading = false;

      return true;
    },
    async sednEventToDb() {
      await this.$db.add("tickets_events", this.ticket_event);
      return true;
    },
    async sendResponse() {
      this.isLoading = true;
      await this.addEventData();
      this.ticket_event.is_redirection = false;
      var updated = await this.updateTicketStatus();
      if (updated) {
        var result = await this.sednEventToDb();
        if (result) {
          this.$toast.success("Відповідь надіслано");
          this.$router.push("/tickets/all");
        } else {
          this.$toast.error("Сталася помилка");
        }
      }
      this.isLoading = false;
    },

    async makeRedirection() {
      this.isLoading = true;
      await this.addEventData();
      this.ticket_event.is_redirection = true;
      this.ticket_event.next_status = 3;
      var updated = await this.updateTicketStatus();
      if (updated) {
        var result = await this.sednEventToDb();
        if (result) {
          this.$toast.success("Запит переадресовано");
          this.$router.push("/tickets/all");
        } else {
          this.$toast.error("Сталася помилка");
        }
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getTicket();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
